<template>
  <Start title="Logged out">
    <div>You have logged out.</div>
    <template #footer>
      <TextButton label="Log back in" @click="goHome()" />
    </template>
  </Start>
</template>

<script lang="ts" setup>
import { useAuthProvider } from "@/common/auth/authStore";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";

const authProvider = useAuthProvider();

async function goHome() {
  await authProvider.loginWithRedirect();
}
</script>
