import { authGuard, createAuthProvider } from "@/common/auth/authStore";
import { environment } from "@/common/environments/environmentLoader";
import { Sentry } from "@/common/monitoring/sentry/sentry";
import { useFailureStore } from "@/common/stores/failureStore";
import FloatingVue from "floating-vue";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { useSentry } from "../common/monitoring/sentry/sentryStore";
import App from "./App.vue";
import router, { noAuthEndpoints } from "./router";

const app = createApp(App);
app.use(FloatingVue, {
  themes: {
    "icon-button-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
    },
  },
});
const pinia = createPinia();
app.use(pinia);
app.use(router);

let sentry: Sentry | undefined = undefined;
const sentryStore = useSentry();
if (sentryStore.enabled) {
  sentry = sentryStore.initialize(app);
}

useFailureStore().registerApp(app);

const authProvider = createAuthProvider(environment.require("AUTH_TYPE"), sentry);
app.use(authProvider);
router.beforeEach((to) => {
  if (noAuthEndpoints.includes(to.fullPath)) {
    return true;
  }
  return authGuard(to);
});
app.mount("#app");
