import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "min-h-[46px]" }
const _hoisted_2 = {
  key: 0,
  class: "flex-start flex flex-row gap-5 bg-gray-800 p-10"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row flex-wrap gap-15 p-15"
}
const _hoisted_4 = {
  key: 0,
  class: "text-lg"
}
const _hoisted_5 = {
  key: 1,
  class: "text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["AsyncWrapper"], {
      async: $props.modules,
      overlay: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          ($setup.selectedModules.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                ($props.allowDeletes)
                  ? (_openBlock(), _createBlock($setup["TextButton"], {
                      key: 0,
                      label: `Delete ${$setup.selectedModules.length} ${$setup.pluralize(
            'project',
            $setup.selectedModules.length
          )}`,
                      icon: "md:delete",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.deletingModules = true))
                    }, null, 8 /* PROPS */, ["label"]))
                  : _createCommentVNode("v-if", true),
                _createVNode($setup["TextButton"], {
                  label: "Clear selection",
                  icon: "md:cancel",
                  onClick: $setup.deselectModules
                })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        ($props.modules.status === $setup.AsyncStatus.Succeeded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.modules.result, (m) => {
                return (_openBlock(), _createBlock($setup["Module"], {
                  key: m.id,
                  module: m,
                  onOpenModule: ($event: any) => ($setup.emit('openModule', m)),
                  onDeleteModule: ($event: any) => ($setup.emit('deleteModule', [m]))
                }, null, 8 /* PROPS */, ["module", "onOpenModule", "onDeleteModule"]))
              }), 128 /* KEYED_FRAGMENT */)),
              ($props.modules.result.length === 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    ($props.filtered)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "No matching projects found"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, "No projects found"))
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["async"]),
    ($setup.deletingModules)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: "Delete projects?",
          "success-label": "Delete",
          onSucceeded: _cache[1] || (_cache[1] = ($event: any) => {
      $setup.emit('deleteModule', $setup.selectedModules);
      $setup.deletingModules = false;
    }),
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => ($setup.deletingModules = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete " + _toDisplayString($setup.selectedModules.length) + " " + _toDisplayString($setup.pluralize("project", $setup.selectedModules.length)) + "? This cannot be undone. ", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}