import { useSettingsStore } from "@/common/stores/settingsStore";
import RedirectPage from "@/home/views/RedirectPage.vue";
import UserHome from "@/home/views/UserHome.vue";

import { environment } from "@/common/environments/environmentLoader";
import { createSharedRoutes } from "@/common/lib/routing";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import Logout from "../views/Logout.vue";
import ResetPasswordPage from "../views/ResetPasswordPage.vue";
import SignupPage from "../views/SignupPage.vue";

export const noAuthEndpoints = ["/logout"];

const routes: Array<RouteRecordRaw> = [
  ...createSharedRoutes(UserHome),
  {
    path: "/redirect",
    component: RedirectPage,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
];

if (environment.require("AUTH_TYPE") === "backend") {
  routes.push({
    path: "/login",
    name: "login",
    component: LoginPage,
  });
  noAuthEndpoints.push("/login");
  if (environment.requireBoolean("AUTH_BACKEND_SIGNUP")) {
    routes.push({
      path: "/signup",
      name: "signup",
      component: SignupPage,
    });
    noAuthEndpoints.push("/signup");
  }
  if (environment.requireBoolean("AUTH_BACKEND_RESET_PASSWORD")) {
    routes.push({
      path: "/reset-password",
      name: "reset-password",
      component: ResetPasswordPage,
    });
    noAuthEndpoints.push("/reset-password");
  }
}

const router = createRouter({
  history: createWebHistory("/home"),
  routes,
});

router.beforeEach((to, from, next) => {
  const settingsStore = useSettingsStore();
  settingsStore.handleRouteChange(to.name); // Call the method to handle route change
  next();
});
export default router;
