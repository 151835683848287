<template>
  <span
    class="category"
    :class="{ selected: selected }"
    @click="emit('selected')"
    @mouseover="hovered = true"
    @mouseout="hovered = false"
  >
    <Icon :name="icon" :color="iconColor"></Icon>
    <p>{{ label }}</p>
  </span>
</template>

<style lang="scss" scoped>
.category {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  user-select: none;

  padding: 0 calc(2 * $wide-margin);

  > p {
    margin: $normal-margin;
  }

  &.selected {
    background-color: $orange;
  }
}

.category:hover:not(.selected) {
  background: $gray1b;
  color: $orange;
}
</style>

<script lang="ts" setup>
import { computed, ref } from "vue";
import Icon from "./Icon.vue";

const props = defineProps<{ label: string; selected: boolean; icon: string }>();
const emit = defineEmits(["selected"]);

const hovered = ref(false);

const iconColor = computed(() => {
  if (props.selected) {
    return "black";
  }
  if (hovered.value) {
    return "orange";
  }
  return "white";
});
</script>
