<template>
  <div id="main">
    <div id="content">
      <router-view />
    </div>
  </div>
  <div id="modal-container"></div>
  <FailureDialog />
</template>

<style lang="scss">
@import "@/style.css";
@import "@/home/styles/global.scss";
@import "~floating-vue/dist/style.css";
@import "~vue-select/dist/vue-select.css";

#main {
  @include full-size;
  display: flex;
  flex-direction: column;
}

#content {
  position: relative;
  flex: 0 1 100%;
  color: $gray11;
  display: flex;
  flex-direction: column;
}
</style>

<script lang="ts" setup>
import { loadHubspotTracking } from "@/common/lib/hubspot";
import FailureDialog from "@/common/components/FailureDialog.vue";
import { onMounted, provide, ref } from "vue";
import { DarkMode } from "@/common/lib/keys";

const darkMode = ref(true);
provide(DarkMode, darkMode);

onMounted(() => {
  if (darkMode.value) document.querySelector("body")?.classList.add("dark");
  loadHubspotTracking();
});
</script>
