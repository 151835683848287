// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-78218a6c]:root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.container[data-v-78218a6c] {\n  height: 34px;\n  padding-left: 15px;\n  color: var(--Light-Gray);\n}\n.container[data-v-78218a6c]:hover {\n  color: var(--CT-Orange);\n  background: #222;\n}\n.container-selected[data-v-78218a6c] {\n  background: var(--CT-Orange);\n}\n.container-selected[data-v-78218a6c]:hover {\n  color: var(--Light-Gray);\n  background: var(--CT-Orange);\n}\n.single-user-mode[data-v-78218a6c] {\n  display: flex;\n  padding: 5px 5px 5px 20px;\n  align-items: center;\n  gap: 10px;\n}\n.user-name[data-v-78218a6c] {\n  font-size: 14px;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n}\n.menu-list-with-icon[data-v-78218a6c] {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  flex-shrink: 0;\n  cursor: pointer;\n}\n.account-circle[data-v-78218a6c] {\n  width: 32px;\n  height: 32px;\n  flex-shrink: 0;\n  border-radius: 50%;\n  padding: 5px;\n}\n.auth-loading[data-v-78218a6c] {\n  padding-left: 15px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
