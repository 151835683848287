<template>
  <HomeTitleBar />

  <div class="content body">
    <div
      class="project-container"
      :class="{
        '-wide': sourceBrowserExpanded,
        '-narrow': !sourceBrowserExpanded,
        'pointer-events-none opacity-50': workspacesState === WorkspacesState.NoWorkspaces,
      }"
    >
      <div>
        <span class="user-line">
          <UserButton v-model="userSelected"></UserButton>
        </span>

        <div
          :class="{
            'pointer-events-none opacity-50': workspacesState === WorkspacesState.NoCurrent,
          }"
        >
          <Textbox
            v-model="projectSearchRef"
            type="search"
            class="padding"
            ref="searchProjectsBox"
          />

          <div class="categories" :class="{ '-sb-retracted': !sourceBrowserExpanded }">
            <span class="title-line">
              <div class="title">Projects</div>
            </span>

            <TextButton
              data-test="new-project"
              label="New Project"
              icon="new-project"
              :menu="true"
              @click="newProject"
              class="new-project-button"
            />
            <MenuListItem
              label="My Projects"
              icon="claritype-thin"
              :selected="showFilter('all')"
              @selected="showModules()"
            />
            <MenuListItem
              v-if="environment.requireBoolean('READER_VIEW_PUBLISHING_ENABLED')"
              label="Reader Views"
              icon="md:bar_chart"
              :selected="showFilter('reader')"
              @selected="showReaderModules()"
            />
            <MenuListItem
              v-if="environment.requireBoolean('ENABLE_PUBLISHING')"
              label="Shared Projects"
              icon="md:share"
              :selected="showFilter('shared')"
              @selected="showSharedModules()"
            />
            <MenuListItem
              v-if="environment.requireBoolean('LOCAL_EXPORTS')"
              label="Exported Projects"
              icon="md:computer"
              :selected="showFilter('local')"
              @selected="showLocalModules()"
            />
          </div>
        </div>
      </div>
      <div class="source-browser-container-container" ref="parentDiv">
        <SourceBrowser
          @select-tables="sourceBrowserTablesSelected"
          :parent-height="divHeight"
          @expanded="adjustColumnWidth"
          :allow-workspace-switching="true"
        />
      </div>
    </div>

    <div class="modules-container subpage-container" v-if="subPage === 'modules'">
      <div class="scrollable">
        <Modules
          :modules="modules"
          :filtered="projectSearchRef.length > 0"
          :allow-deletes="activeFilter === 'all'"
          @open-module="openModule"
          @delete-module="deleteModule($event)"
        />
      </div>
    </div>
    <div class="subpage-container" v-if="subPage === 'user-settings'">
      <UserSettings />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.padding {
  padding: $normal-margin;
}
.body {
  flex: 0 1 100%;
  display: flex;
  flex-flow: row nowrap;
}

.project-container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  background-color: $gray3;
  height: 100%;
  justify-content: space-between;
  &.-narrow {
    width: 300px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }
  &.-wide {
    width: 450px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }
  .user-line {
    display: flex;

    > * {
      width: 100%;
    }
  }
  .categories {
    h4 {
      padding: 0 $wide-margin;
    }

    .new-button {
      margin-left: calc($wide-margin + $thin-margin);
      margin-bottom: $normal-margin;
    }

    &.-sb-retracted {
      flex-grow: 1;
    }
  }

  .title-line {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    padding: 0 $wide-margin;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 0;

      color: var(--Light-Gray);
      font-size: 14px;
      font-weight: $weight-bold;
      margin: $normal-margin 0;
    }
  }
}
.subpage-container {
  flex: 1 0 70%;
  position: relative;
  background-color: var(--Canvas);
}
.modules-container {
  > * {
    padding: $ludicrous-margin;
  }
}
.scrollable {
  @include full-size;
  overflow-y: scroll;
}
.source-browser-container-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  max-height: 65%;
}

.new-project-button {
  margin-left: 24px;
  margin-bottom: 17px;
}
</style>

<script lang="ts" setup>
import MenuListItem from "@/common/components/MenuListItem.vue";
import SourceBrowser from "@/common/components/SourceBrowser.vue";
import TextButton from "@/common/components/TextButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { Async, asyncNotStarted, AsyncStatus, asyncSucceeded } from "@/common/lib/async";
import { MapAction } from "@/common/lib/mapActions";
import { useWorkspaceStore, WorkspacesState } from "@/common/stores/workspaceStore";
import { SourceSelection } from "@/common/stores/sourceBrowser";
import { useSettingsStore, Routes } from "@/common/stores/settingsStore";
import UserButton from "@/home/components/user-home/HomeUserButton.vue";

import UserSettings from "@/common/components/UserSettings.vue";
import Modules from "@/home/components/user-home/Modules.vue";
import { ModuleMetadata, useUserModuleStore } from "@/common/stores/userModuleStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, Ref, ref, watch } from "vue";
import HomeTitleBar from "../components/user-home/HomeTitleBar.vue";
import { capitalize, isEmpty, uniq } from "lodash";
import { useResizeListener } from "@/common/composables/useResizeListener";
import { useRouter } from "vue-router";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";

const userStore = useUserModuleStore();
const navigation = useNavigation();
const settingStore = useSettingsStore();
const workspaceStore = useWorkspaceStore();
const router = useRouter();

const { workspacesState } = storeToRefs(workspaceStore);
const { subPage, userSelected } = storeToRefs(settingStore);

const activeFilter: Ref<"all" | "shared" | "local" | "reader"> = ref("all");
const sourceBrowserExpanded = ref(true);
const projectSearchRef = ref("");
const parentDiv = ref<HTMLElement | null>(null);
const divHeight = ref(0);

const calculateDivHeight = () => {
  divHeight.value = parentDiv.value?.offsetHeight ?? 0;
};

const modules = computed(() => {
  let modules: Async<ModuleMetadata[]> = asyncNotStarted();
  switch (activeFilter.value) {
    case "all":
      modules = userStore.modules;
      break;
    case "shared":
      modules = userStore.sharedModules;
      break;
    case "local":
      modules = userStore.localModules;
      break;
    case "reader":
      modules = userStore.readerModules;
      break;
    default:
      return modules;
  }
  if (modules.status !== AsyncStatus.Succeeded) {
    return modules;
  }
  return asyncSucceeded(
    modules.result.filter((m) => {
      const searchTerm = projectSearchRef.value.toLowerCase();
      return (
        m.name.toLowerCase().includes(searchTerm) ||
        (activeFilter.value === "shared" && m.owner_name?.toLowerCase().includes(searchTerm))
      );
    })
  );
});

const searchProjectsBox = ref<typeof Textbox | undefined>(undefined);
const findKeyCommand = KeyCommand.readKeyCommand(
  environment.require("KEY_COMMAND_HOME_SEARCH_PROJECTS")
);
useKeyHandler().registerHandler(
  findKeyCommand,
  () => searchProjectsBox.value?.focus(),
  true,
  false
);

watch(userSelected, (newValue) => {
  if (newValue === false) {
    router.replace({ name: Routes.Home });
  } else {
    if (settingStore.selectedTab === "connection") {
      router.replace({ name: Routes.Connections });
    } else {
      router.replace({ name: Routes.UserSettings });
    }
  }
});

function showModules() {
  userSelected.value = false;
  activeFilter.value = "all";
}

function showSharedModules() {
  userSelected.value = false;
  userStore.loadShared();
  activeFilter.value = "shared";
}

function showLocalModules() {
  userSelected.value = false;
  userStore.loadLocal();
  activeFilter.value = "local";
}

function showReaderModules() {
  userSelected.value = false;
  userStore.loadReader();
  activeFilter.value = "reader";
}

async function sourceBrowserTablesSelected(selections: SourceSelection[]) {
  const action = new MapAction({});
  selections.forEach((s) => action.addDbxTable(s.table));
  const moduleId = await userStore.newModule();
  let name = undefined;
  if (environment.requireBoolean("USE_TABLE_NAME_AS_PROJECT_NAME")) {
    name = autogenerateProjectName(selections);
  }
  await userStore.updateModule(moduleId, { name, map: action.map });
  navigation.openProject(moduleId, true);
}

async function newProject() {
  const moduleId = await userStore.newModule();
  await userStore.updateModule(moduleId, { map: { in_connections: {} } });
  navigation.openProject(moduleId, true);
}

async function openModule(module: ModuleMetadata) {
  switch (activeFilter.value) {
    case "all": {
      navigation.openProject(module.id);
      break;
    }
    case "shared": {
      // Load shared module
      const copyId = await userStore.duplicateSharedModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case "local": {
      // Load shared module
      const copyId = await userStore.duplicateLocalModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case "reader": {
      navigation.goToUrl(`/reader/modules/${module.id}/explore`);
    }
  }
}

async function deleteModule(modules: ModuleMetadata[]) {
  switch (activeFilter.value) {
    case "all": {
      await Promise.all(modules.map((m) => userStore.deleteModule(m.id)));
      break;
    }
    case "shared": {
      await Promise.all(modules.map((m) => userStore.deleteSharedModule(m.id)));
      await userStore.loadShared();
      break;
    }
  }
}

function showFilter(filterName: string): boolean {
  if (userSelected.value) {
    return false;
  }
  return activeFilter.value === filterName;
}

function adjustColumnWidth(expanded: boolean) {
  sourceBrowserExpanded.value = expanded;
}

function autogenerateProjectName(selections: SourceSelection[]): string | undefined {
  if (isEmpty(selections)) {
    return undefined;
  }
  if (selections.length > 1) {
    // Try schema name if multiple selections are all in the same schema
    const schemas = uniq(selections.map((sel) => sel.table.schema));
    if (schemas.length === 1) {
      return prettifyTableName(schemas[0]);
    }
  }
  return prettifyTableName(selections[0].table.name);
}

function prettifyTableName(tableName: string): string {
  return tableName.replaceAll(/[_-]/g, " ").split(" ").map(capitalize).join(" ");
}

useResizeListener(calculateDivHeight);

onMounted(() => {
  calculateDivHeight();
});

watch(
  () => workspaceStore.currentWorkspace,
  (workspace) => {
    if (workspace.status !== AsyncStatus.Succeeded) {
      return;
    }
    if (workspace.result?.connected) {
      userStore.boot(workspace.result.id);
    } else {
      router.replace({ name: Routes.Connections });
    }
  }
);
</script>
