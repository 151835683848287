<template>
  <AsyncWrapper :async="modules" :overlay="false">
    <div class="min-h-[46px]">
      <div
        v-if="selectedModules.length > 0"
        class="flex-start flex flex-row gap-5 bg-gray-800 p-10"
      >
        <TextButton
          v-if="allowDeletes"
          :label="`Delete ${selectedModules.length} ${pluralize(
            'project',
            selectedModules.length
          )}`"
          icon="md:delete"
          @click="deletingModules = true"
        />
        <TextButton label="Clear selection" icon="md:cancel" @click="deselectModules" />
      </div>
    </div>
    <div
      class="flex flex-row flex-wrap gap-15 p-15"
      v-if="modules.status === AsyncStatus.Succeeded"
    >
      <Module
        v-for="m in modules.result"
        :key="m.id"
        :module="m"
        @open-module="emit('openModule', m)"
        @delete-module="emit('deleteModule', [m])"
      />
      <template v-if="modules.result.length === 0">
        <div class="text-lg" v-if="filtered">No matching projects found</div>
        <div class="text-lg" v-else>No projects found</div>
      </template>
    </div>
  </AsyncWrapper>

  <Dialog
    v-if="deletingModules"
    title="Delete projects?"
    success-label="Delete"
    @succeeded="
      emit('deleteModule', selectedModules);
      deletingModules = false;
    "
    @cancelled="deletingModules = false"
  >
    Are you sure you want to delete {{ selectedModules.length }}
    {{ pluralize("project", selectedModules.length) }}? This cannot be undone.
  </Dialog>
</template>

<script lang="ts" setup>
import { Async, AsyncStatus } from "@/common/lib/async";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { ModuleMetadata } from "@/common/stores/userModuleStore";
import Module from "./Module.vue";
import { computed, ref } from "vue";
import TextButton from "@/common/components/TextButton.vue";
import pluralize from "pluralize";
import Dialog from "@/common/components/Dialog.vue";

const props = defineProps<{
  modules: Async<ModuleMetadata[]>;
  filtered: boolean;
  allowDeletes?: boolean;
}>();

const emit = defineEmits(["openModule", "deleteModule"]);

const deletingModules = ref(false);

const selectedModules = computed(() => {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  return props.modules.result.filter((m) => m.selected);
});

function deselectModules() {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  props.modules.result.forEach((m) => (m.selected = false));
}
</script>
