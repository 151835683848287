<template>
  <Start title="Create your claritype account">
    <div class="flex flex-col gap-10">
      <form>
        <div class="text-lg text-white">
          Please provide an email address and a unique password for your claritype account.
        </div>
        <div class="text-lg text-white">
          Already have claritype account?
          <RouterLink to="/login" class="text-orange">Log in</RouterLink>
        </div>
        <div>
          <Textbox label="Name" autocomplete="name" id="name-field" />
          <Textbox label="Email" type="email" autocomplete="email" id="email-field" />
          <Textbox
            label="Create password"
            type="password"
            autocomplete="new-password"
            id="password-field"
          />

          <Textbox
            label="Retype password"
            type="password"
            autocomplete="new-password"
            id="confirm-password-field"
          />
          <Textbox label="Company name (optional)" autocomplete="organization" id="company-field" />
        </div>
        <div class="text-base">
          <Checkbox
            label="Get all the pro tips, tricks, and updates with our newsletter.*"
            :model-value="true"
            id="newsletter-checkbox"
          />
          <Checkbox
            label="I’d like a demo to find out more."
            :model-value="false"
            id="demo-checkbox"
          />
        </div>
        <div class="flex flex-col gap-20 text-sm text-gray-500">
          <div>By submitting this form, you agree to the terms of service and privacy policy.</div>
          <div>
            *By checking the box and entering your email address above, you consent to receive
            marketing emails (such as newsletters, blog posts, webinars, event invitations and new
            product updates) from claritype from time to time. You can unsubscribe at any time by
            clicking on the “Unsubscribe” link at the bottom of our emails. For more information on
            how we process your personal information and what rights you have in this respect,
            please see our privacy policy.
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <TextButton label="Create" />
    </template>
  </Start>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";
</script>
