<template>
  <div class="title-bar" @dragover.prevent @drop.prevent>
    <div class="left-controls">
      <img src="/images/lockup.svg" class="full-logo" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-bar {
  display: flex;
  position: relative;
  background: $gray3;
  flex: 0 0 $title-bar-height;
  border-bottom: 1px solid $gray2;
  height: $title-bar-height;
}

.full-logo {
  margin: 0 $wide-margin;
  width: 115px;
  height: 25px;
  align-self: center;
}

.left-controls {
  display: flex;
  flex-basis: 33%;
  align-items: center;
  gap: $normal-margin;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>

<script lang="ts" setup></script>
