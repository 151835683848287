<template>
  <Start v-if="mode === 'email'" icon="md:lock_reset" title="Reset password">
    <div class="flex flex-col gap-10">
      <div class="text-lg text-white">
        Enter the email address of your claritype user account and we’ll send you a link to reset
        your password.
      </div>
      <div>
        <Textbox label="Email" type="email" autocomplete="email" id="email-field" v-model="email" />
      </div>
    </div>
    <template #footer>
      <template v-if="!connecting">
        <TextButton label="Back" :secondary="true" @click="goToLogin" />
        <TextButton label="Reset password" @click="resetPassword" :disabled="!formValidated" />
      </template>
      <SpinnerButton v-else label="Resetting password…" />
    </template>
  </Start>
  <Start v-if="mode === 'check'" icon="md:mark_email_read" title="Email sent">
    <div class="flex flex-col gap-10">
      <div class="text-lg text-white">Check your inbox for next steps.</div>
    </div>
    <template #footer>
      <TextButton label="Done" @click="goToLogin" />
    </template>
  </Start>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";
import { BackendAuthProvider } from "@/common/auth/backendAuthProvider";
import { useBackendAuth } from "@/common/auth/backendAuth";
import { useAuthProvider } from "@/common/auth/authStore";
import { isEmpty } from "lodash";

const backendAuth = useBackendAuth();
const backendProvider = useAuthProvider();
const connecting = ref(false);

const mode = ref<"email" | "check">("email");
const email = ref("");

const router = useRouter();

function goToLogin() {
  router.push("/login");
}

async function resetPassword() {
  connecting.value = true;
  try {
    if (!(backendProvider instanceof BackendAuthProvider)) {
      throw new Error("Login page required backend auth provider");
    }
    if (isEmpty(email.value)) {
      return;
    }
    // Send login request to backend
    await backendAuth.resetPassword(email.value);
    mode.value = "check";
  } finally {
    connecting.value = false;
  }
}

const formValidated = computed(() => {
  if (email.value.trim().length <= 3 || !email.value.includes("@")) {
    return false;
  }
  return true;
});
</script>
