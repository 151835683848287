<template>
  <Start
    v-if="mode === 'email'"
    icon="md:lock_reset"
    title="Reset password"
    success-label="Connect"
    cancel-label="Back"
    @succeeded="mode = 'check'"
    @cancelled="goToLogin"
  >
    <div class="flex flex-col gap-10">
      <div class="text-lg text-white">
        Enter the email address of your claritype user account and we’ll send you a link to reset
        your password.
      </div>
      <div>
        <Textbox label="Email" type="email" autocomplete="email" id="email-field" />
      </div>
    </div>
    <template #footer>
      <TextButton label="Back" :secondary="true" @click="goToLogin" />
      <TextButton label="Connect" @click="mode = 'check'" />
    </template>
  </Start>
  <Start v-if="mode === 'check'" icon="md:mark_email_read" title="Email sent">
    <div class="flex flex-col gap-10">
      <div class="text-lg text-white">Check your inbox for next steps.</div>
    </div>
    <template #footer>
      <TextButton label="Close" @click="mode = 'reset'" />
    </template>
  </Start>
  <Start v-if="mode === 'reset'" icon="md:lock_reset" title="Reset password">
    <div class="flex flex-col gap-10">
      <div class="text-lg text-white">
        Enter the email address of your claritype user account and we’ll send you a link to reset
        your password.
      </div>
      <div>
        <Textbox class="hidden" label="Email" autocomplete="email" id="email" />
        <Textbox
          label="New password"
          type="password"
          autocomplete="new-password"
          id="new-password-field"
        />
        <Textbox
          label="Confirm password"
          type="password"
          autocomplete="new-password"
          id="confirm-password-field"
        />
      </div>
    </div>
    <template #footer>
      <TextButton label="Cancel" :secondary="true" @click="goToLogin" />
      <TextButton label="Reset" @click="goToLogin" />
    </template>
  </Start>
</template>

<script lang="ts" setup>
import Textbox from "@/common/components/Textbox.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";

const mode = ref<"email" | "check" | "reset">("email");

const router = useRouter();

function goToLogin() {
  router.push("/login");
}
</script>
