import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["category", { selected: $props.selected }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('selected'))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($setup.hovered = true)),
    onMouseout: _cache[2] || (_cache[2] = ($event: any) => ($setup.hovered = false))
  }, [
    _createVNode($setup["Icon"], {
      name: $props.icon,
      color: $setup.iconColor
    }, null, 8 /* PROPS */, ["name", "color"]),
    _createElementVNode("p", null, _toDisplayString($props.label), 1 /* TEXT */)
  ], 34 /* CLASS, NEED_HYDRATION */))
}