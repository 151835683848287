import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78218a6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "auth-loading user-name"
}
const _hoisted_2 = {
  key: 0,
  class: "menu-list-with-icon"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-name" }
const _hoisted_5 = {
  key: 1,
  class: "single-user-mode user-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { 'container-selected': $setup.selected }]),
    onClick: $setup.toggle
  }, [
    ($setup.auth.isEnabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($setup.auth.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading User…"))
            : ($setup.auth.isAuthenticated)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  ($setup.user)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("img", {
                          src: $setup.user.picture,
                          class: "account-circle"
                        }, null, 8 /* PROPS */, _hoisted_3),
                        _createElementVNode("div", _hoisted_4, _toDisplayString($setup.user.name), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createBlock($setup["TextButton"], {
                  key: 2,
                  label: "Login",
                  onClick: $setup.login
                }))
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode($setup["Icon"], {
            name: "person",
            color: "white"
          }),
          _createTextVNode(" Single User Mode ")
        ]))
  ], 2 /* CLASS */))
}