<template>
  <div class="container" :class="{ 'container-selected': selected }" @click="toggle">
    <template v-if="auth.isEnabled">
      <div v-if="auth.isLoading" class="auth-loading user-name">Loading User…</div>
      <template v-else-if="auth.isAuthenticated">
        <div class="menu-list-with-icon" v-if="user">
          <img :src="user.picture" class="account-circle" />
          <div class="user-name">{{ user.name }}</div>
        </div>
      </template>
      <template v-else>
        <TextButton label="Login" @click="login" />
      </template>
    </template>
    <template v-else>
      <div class="single-user-mode user-name">
        <Icon name="person" color="white" />
        Single User Mode
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
$user-button-username-font-size: 14px;
$user-button-username-font-weight: 500;
.container {
  height: 34px;
  padding-left: $wide-margin;
  color: var(--Light-Gray);
  &:hover {
    color: var(--CT-Orange);
    background: $gray1b;
  }
  &-selected {
    background: var(--CT-Orange);
    &:hover {
      color: var(--Light-Gray);
      background: var(--CT-Orange);
    }
  }
}
.single-user-mode {
  display: flex;
  padding: $thin-margin $thin-margin $thin-margin $wider-margin;
  align-items: center;
  gap: $normal-margin;
}
.user-name {
  font-size: $user-button-username-font-size;
  font-weight: 500;
  user-select: none;
}
.menu-list-with-icon {
  display: flex;
  align-items: center;
  gap: $normal-margin;
  flex-shrink: 0;

  // Extra
  cursor: pointer;
}
.account-circle {
  width: 32px;
  height: 32px;
  flex-shrink: 0;

  // Extra
  border-radius: 50%;
  padding: $thin-margin;
}
.auth-loading {
  padding-left: $wide-margin;
}
</style>

<script lang="ts" setup>
import { useAuthProvider } from "@/common/auth/authStore";
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";

const props = defineProps<{ modelValue?: boolean }>();
const emit = defineEmits(["update:modelValue"]);

const selected = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const auth = useAuthProvider();
const userStore = useUserStore();
const userStoreRefs = storeToRefs(userStore);

const user = computed(() => userStoreRefs.user?.value);

function login() {
  auth.loginWithRedirect();
}

function toggle() {
  selected.value = !selected.value;
}
onMounted(() => {
  userStore.reloadUser();
});
</script>
