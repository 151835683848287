<template>
  <div class="content">Redirecting…</div>
</template>
<style lang="scss" scoped>
.content {
  font-size: 18px;
  margin: $ludicrous-margin;
}
</style>
<script lang="ts" setup>
import { environment } from "@/common/environments/environmentLoader";
import { getCookie } from "@/common/lib/cookie";
import { onMounted } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { userSettings } = storeToRefs(userStore);

onMounted(async () => {
  await userStore.reloadUser();
  const defaultUrl = `${location.origin}${environment.require("DEFAULT_LANDING_PAGE")}`;
  const redirectUrl = getCookie("ct_redirect_url");
  let customUrl = userSettings?.value?.default_homepage;
  customUrl = !customUrl || customUrl === "/" ? undefined : `${location.origin}${customUrl}`;
  document.location.href = customUrl ?? redirectUrl ?? defaultUrl;
});
</script>
